import { LitElement, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import "@idme/icon";
import { styles, SIZES } from "./styles";
import { createEvent } from "@idme/component-utilities";

const DISMISSED = "dismissed";
const PERSIST = "persist";

const convertDismissable = (value) =>
  value === PERSIST ? value : value === "";

@customElement("idme-banner")
export class IDmeBanner extends LitElement {
  static styles = styles;

  @property({ type: String }) size: keyof typeof SIZES = "small";

  @property({ converter: convertDismissable }) dismissable:
    | boolean
    | typeof PERSIST = false;

  @state() dismissed = false;

  /**
   * Unique ID for storing whether this banner is dismissed. Seems appropriate
   * to make that the ID for now.
   */
  private get persistId(): string | undefined {
    if (
      typeof window.sessionStorage !== "undefined" &&
      this.dismissable === PERSIST &&
      this.id
    ) {
      return `idme-banner-${this.id}`;
    }
  }

  private get _title(): string {
    return this.title ? `Banner: ${this.title}` : "Banner";
  }

  private onClickDismiss(): void {
    if (this.persistId) {
      sessionStorage.setItem(this.persistId, DISMISSED);
    }
    this.dismissed = true;
    this.dispatchEvent(createEvent("dismiss"));
  }

  willUpdate() {
    if (
      this.persistId &&
      sessionStorage.getItem(this.persistId) === DISMISSED
    ) {
      this.dismissed = true;
    }
  }

  render() {
    const classes = {
      banner: true,
      visible: !this.dismissed,
    };
    return html`<aside title="${this._title}" class="${classMap(classes)}">
      <div class="banner-content"><slot /></div>
      ${this.dismissable
        ? html`<button
            @click=${this.onClickDismiss}
            class="banner-dismiss"
            aria-label="Dismiss"
          >
            <idme-icon
              name="x"
              size="${this.size === "large" ? "large" : "medium"}"
            />
          </button>`
        : nothing}
    </aside>`;
  }
}
