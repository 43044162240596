/**
 * Convenience class for quickly creating scoped Web Component styles
 * that are driven by HTML attribute values.
 */
import { type CSSResult, CSSResultArray, unsafeCSS } from "lit";
export type StyleValue = CSSResult | string;

export type ThemeValueDef = {
  private?: boolean;
  value: StyleValue;
};

export type ThemeValue = ThemeValueDef | StyleValue | string;
type ThemeProperty = {
  attribute: string;
  variant: string;
  properties: Record<string, ThemeValue>;
};

/**
 * An object defining a set of CSS values to be applied to the custom element
 * when an attribute is set to a particular value. The attribute and value are
 * set by the ThemeAttribute and ThemeDefinition objects which contain this.
 * Names will be set as CSS custom variables.
 * @example
 * ```js
 * const textMedium = {
 *  fontSize: css`1.25em`,
 *  fontWeight: css`400`
 * }
 * const textThicc = {
 *  fontSize: css`1.5em`,
 *  fontWeight: css`600`
 * }
 * ```
 * when assigned to a ThemeAttribute will set the custom CSS variables `--font-size` and `--font-weight`.
 */
export type ThemeVariant = Record<string, ThemeValue>;

/**
 * An object whose keys are the possible values of an HTML attribute (e.g. "primary" and "secondary" for the "variant"
 * attribute) and whose values are ThemeVariants describing a set of custom CSS values to apply to elements where that
 * attribute is set to that value.
 * @example
 * ```js
 * const emphases = {
 *   important: textMedium,
 *   urgent: textThicc
 * }
 * ```
 * when assigned to a ThemeDefinition as "emphasis", will set CSS variables for `textMedium` when the component
 * has attribute `emphasis="important"`, and for `textThicc` when `emphasis="urgent"`.
 */
export type ThemeAttribute = Record<string, ThemeVariant>;

/**
 * An object whose keys are HTML attribute names (e.g. "variant", "treatment", "type")
 * and whose values are ThemeAttributes describing style options for possible values of those attributes.
 * @example
 * ```js
 * const myTheme = {
 *   emphasis: emphases
 * }
 * ```
 * defines CSS to apply to various values of the attribute `emphasis`.
 */
export type ThemeDefinition = Record<string, ThemeAttribute>;

type ThemeInitializer = {
  namespace: string;
  definition: ThemeDefinition;
  defaults: ThemeVariant;
};

export class Theme {
  /**
   * Should be the name of the component being styled.
   * @private
   */
  private readonly namespace: string;
  private readonly themeDef: ThemeDefinition;
  private readonly defaults: ThemeVariant;
  // For public overrides
  protected publicPrefix = "idme";
  // For internal convenience proxies
  private internalPrefix = "i";

  // CSS variables passed in to the custom stylesheet you may provide.
  current: Record<keyof typeof this.defaults, CSSResult>;

  constructor({ namespace, definition, defaults }: ThemeInitializer) {
    this.namespace = kebabize(namespace);
    this.themeDef = definition;
    this.defaults = defaults;
    this.current = Object.keys(defaults).reduce(
      (out, name) => ({ ...out, [name]: unsafeCSS(`var(--${name})`) }),
      {},
    );
  }

  private isCSSResult(value: unknown): value is CSSResult {
    return (
      !!value && typeof value === "object" && Reflect.has(value, "cssText")
    );
  }

  private isValueDef(value: unknown): value is ThemeValueDef {
    return typeof value === "object" && !this.isCSSResult(value);
  }

  private toCSSText(val: ThemeValue): string {
    return this.isValueDef(val)
      ? this.toCSSText(val.value)
      : this.isCSSResult(val)
        ? val.cssText
        : val;
  }

  private writeAttributeVariant({
    attribute,
    variant,
    properties,
  }: ThemeProperty) {
    let output = `:host([${attribute}="${variant}"]) {`;
    for (const [name, value] of Object.entries(properties)) {
      output += `\n  --${this.internalPrefix}-${kebabize(
        name,
      )}: ${this.toCSSText(value)};`;
    }
    return `${output}\n}\n\n`;
  }

  private writeDefaults(properties: ThemeVariant) {
    let output = `:host {`;
    for (const [propName, value] of Object.entries(properties)) {
      const prop = kebabize(propName);
      output += `\n  --${prop}: `;
      const defaultValue = this.toCSSText(value);
      const proxyDef = `var(--${this.internalPrefix}-${prop}, ${defaultValue})`;
      if (!this.isValueDef(value) || value.private !== true) {
        output += `var(--${this.publicPrefix}-${this.namespace}-${prop}, ${proxyDef});`;
      } else {
        output += `${proxyDef};`;
      }
    }
    return `${output}\n}`;
  }

  toCSS() {
    let output = "";
    for (const [attribute, variantDef] of Object.entries(this.themeDef)) {
      for (const [variant, properties] of Object.entries(variantDef)) {
        output += this.writeAttributeVariant({
          attribute,
          variant,
          properties,
        });
      }
    }
    return unsafeCSS(output + this.writeDefaults(this.defaults));
  }

  /**
   * Add a custom stylesheet to the theme provided. Takes a function that will receive a named dictionary
   * of CSS variables, which at render time will be applied to your stylesheet according to the attribute values
   * of the live element.
   */
  use(
    factory: (theme: typeof this.current) => CSSResult | CSSResultArray,
  ): CSSResultArray {
    let styles = factory(this.current);
    if (!Array.isArray(styles)) {
      styles = [styles];
    }
    return [this.toCSS(), ...styles];
  }
}

// Inlining this case changer function until it's exported properly from
// @idme/component-utilities. It changes "camelCase" to "kebab-case".
function kebabize(value: string) {
  return value
    .split("")
    .map((letter, i) => {
      return letter.toUpperCase() === letter
        ? `${i !== 0 ? "-" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
}
