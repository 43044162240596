import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import { styles } from "./styles";

@customElement("idme-merchandising-ribbon")
export class IDmeMerchandisingRibbon extends LitElement {
  static styles = styles;
  render() {
    return html` <article>
      <slot name="icon"></slot>
      <slot name="title"></slot>
      <slot name="description"></slot>
      <slot name="cta"></slot>
    </article>`;
  }
}
