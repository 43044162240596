import { css } from "lit";
import {
  COLOR,
  Z_INDEX,
  UTILITIES,
  TYPOGRAPHY,
  MEDIA,
} from "@idme/component-utilities";
import { Theme } from "../theme";

export const SIZES = {
  small: {
    size: css`3rem`,
  },
  medium: {
    size: css`4rem`,
  },
  large: {
    size: css`5rem`,
  },
} as const;

export const VARIANTS = {
  bright: {
    bg: COLOR.emerald,
    fg: COLOR.white,
  },
  dark: {
    bg: COLOR.slate,
    fg: COLOR.snow,
  },
  light: {
    bg: COLOR.action,
    fg: COLOR.ink,
  },
} as const;

export const PLACES = {
  top: {
    top: "0",
  },
  bottom: {
    bottom: "0",
  },
  inline: {
    top: {
      private: true,
      value: "auto",
    },
    bottom: {
      private: true,
      value: "auto",
    },
  },
};

const theme = new Theme({
  namespace: "banner",
  definition: {
    variant: VARIANTS,
    size: SIZES,
    place: PLACES,
  },
  defaults: {
    ...SIZES.small,
    ...VARIANTS.bright,
    ...PLACES.inline,
  },
});

export const styles = theme.use(({ fg, bg, size, top, bottom }) => {
  const boxSize = css`calc(${size} + calc(var(--extra-padding, 0px) * 2))`;

  return css`
    .banner {
      box-sizing: border-box;
      visibility: hidden;
      height: 0;
      left: 0;
      right: 0;
      color: ${fg};
      background-color: ${bg};
      display: flex;
      flex-flow: row nowrap;
      place-items: center;
      place-content: space-between;
      padding: 0 calc(${boxSize} / 4);
      top: ${top};
      bottom: ${bottom};
      ${TYPOGRAPHY.body};
      font-size: calc(${size} / 3);
      max-width: 100vw;
    }

    .banner.visible {
      visibility: visible;
      height: ${boxSize};
    }

    .banner-content {
      flex: 1;
      text-align: center;
    }

    .banner-dismiss {
      height: ${boxSize};
      width: ${boxSize};
      flex-basis: ${boxSize};
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      color: ${fg};
      cursor: pointer;
    }

    .banner-dismiss:focus {
      ${UTILITIES.focusOutlineClose}
    }

    :host([dismissable]) aside {
      padding-right: 0;
    }

    :host([place]) {
      width: 100%;
      position: fixed;
      left: 0;
      z-index: ${Z_INDEX.medium};
    }

    :host([place~="top"]) {
      top: 0;
    }

    :host([place~="bottom"]) {
      bottom: 0;
    }

    @media screen and (max-width: ${MEDIA.size.xs}) {
      :host {
        --extra-padding: 3px;
      }
      .banner {
        padding-right: 0;
      }
      .banner-content {
        text-align: left;
      }
    }
  `;
});
